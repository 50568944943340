import React from 'react';
import ContactForm from './ContactForm';
import { Text } from '../context/LanguageCtx';

import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

function ContactFormMobile({ isOpened, handleClose }) {
    return (
        <Dialog className="contact-form-mobile" open={isOpened} onClose={handleClose}>
            <DialogTitle>
                <Text tid="contact.form"/>
                <IconButton className="t-white" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <ContactForm/>
            </DialogContent>
        </Dialog>
    )
}

export default ContactFormMobile
