import React, { useState, useContext, useRef } from 'react';
import emailjs from 'emailjs-com';
import ReCAPTCHA from "react-google-recaptcha";

import { Text, LanguageCtx } from '../context/LanguageCtx';

import { TextField, Button, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

export default function ContactForm() {
  const { getText } = useContext(LanguageCtx);
  const reCAPTCHARef = useRef(null)
  const [email, setEmail] = useState({
    name: null,
    email: null,
    subject: null,
    message: null
  });
  const [notification, setNotification] = useState({
    isOpen: false,
    message: null,
    isError: false
  })

  const handleChange = event => {
    setEmail({ ...email, [event.target.name]: event.target.value })
  }

  const resetReCAPTCHA = () => {
    reCAPTCHARef.current.reset();
    reCAPTCHARef.current.execute();
  }

  function sendEmail(e) {
    e.preventDefault()
    if(!email.name || !email.email || !email.subject || !email.message) {
      setNotification({
        isOpen: true,
        message: getText("contact.notif.fail"),
        isError: true
      })
      return;
    }

    reCAPTCHARef.current.execute();

    emailjs.sendForm('JanDurovcik', 'JanDurovcik_Template', e.target, process.env.EMAIL_USER_ID)
      .then((result) => {
        setNotification({
          isOpen: true,
          message: getText("contact.notif.success"),
          isError: false
        })
      }, (error) => {
        setNotification({
          isOpen: true,
          message: getText("contact.notif.fail"),
          isError: true
        })
      });
  }

  const handleCloseNotification = () => {
    setNotification({
      ...notification,
      isOpen: false
    })
  };

  return (
    <>
      <form className='contact-form t-left' onSubmit={sendEmail}>
        <TextField
          required
          fullWidth
          label={getText("contact.form.name")}
          name="name"
          value={email.name || ""}
          onChange={handleChange}
          />
        <TextField
          required
          fullWidth
          label={getText("contact.form.email")}
          name="email"
          value={email.email || ""}
          onChange={handleChange}
          />
        <TextField
          required
          fullWidth
          label={getText("contact.form.subject")}
          name="subject"
          value={email.subject || ""}
          onChange={handleChange}
        />
        <TextField
          rows={4}
          multiline
          required
          fullWidth
          label={getText("contact.form.message")}
          name="message"
          value={email.message || ""}
          onChange={handleChange}
        />
        <ReCAPTCHA 
          className="recaptcha"
          ref={reCAPTCHARef}
          sitekey={process.env.SITEKEY_RECAPTCHA}
          theme="dark"
          size="invisible"
          onExpired={resetReCAPTCHA}
        />
        <Button type="submit" className='btn btn-2 push-top-hlf'><Text tid="contact.form.send"/></Button>
      </form>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={notification.isOpen}
        onClose={handleCloseNotification}
        autoHideDuration={3000}
      >
        <Alert 
          onClose={handleCloseNotification} 
          variant="filled" 
          severity={`${notification.isError ? "error" : "success"}`}
        >
          {notification.message}
        </Alert >
      </Snackbar>
    </>
  );
}