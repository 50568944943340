import React, { memo, useState, useContext } from "react";
import { Text, LanguageCtx } from '../context/LanguageCtx';

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import ContactForm from '../components/ContactForm'
import ContactFormMobile from '../components/ContactFormMobile'

import { ClickAwayListener, Button, Grid, Box, Hidden } from '@material-ui/core';

import '../assets/css/contact.scss'

function ContactPage() {
    const { getText } = useContext(LanguageCtx);
    const [isOpenedForm, setOpenForm] = useState(false);
    const [isOpenedFormMobile, setOpenFormMobile] = useState(false);

    const openForm = () => {
        setOpenForm(true);
    };

    const handleCloseForm = () => {
        setOpenForm(false);
    };
    
    const openFormMobile = () => {
        setOpenFormMobile(true);
    };

    const handleCloseFormMobile = () => {
        setOpenFormMobile(false);
    };
    
    return (
        <Layout headerType={1}>
            <SEO title={getText("contact")} />
            <Box id="contact-page" className='page content-dark content-padd-top padd-btm-dbl'>
                <h3 className='page-title push-top-dbl padd-top-dbl'><Text tid="contact"/></h3>
                <Box className="push-btm push-top padd-btm-hlf padd-top-dbl">
                    <Grid className="contact-content" container>
                        <Grid className="contact-info" item xs={isOpenedForm ? 6 : 12}>
                            <p className={`${isOpenedForm ? "" : "text-center"}`}>
                                <span><Text tid="contact.manager"/></span>
                                <br/>
                                <span className="o-low push-btm-hlf">Mgr. Miroslav Dufinec</span>
                                <br/>
                                <span><Text tid="contact.phone"/></span>
                                <br/>
                                <span className="o-low push-btm-hlf"><a href="tel:+421908047780">+421 908 047 780</a></span>
                                <br/>
                                <span><Text tid="contact.email"/></span>
                                <br/>
                                <span className="o-low"><a href="mailto:produkcia@jan.sk">produkcia@jan.sk</a></span>
                                <br/>
                                <span className="o-low line"></span>
                                <br/>
                                <span><Text tid="contact.production"/></span>
                                <br/>
                                <span className="o-low push-btm-hlf">Lucie Solanská</span>
                                <br/>
                                <span><Text tid="contact.phone"/></span>
                                <br/>
                                <span className="o-low push-btm-hlf"><a href="tel:+421903805580">+421 903 805 580</a></span>
                                <br/>
                                <span><Text tid="contact.email"/></span>
                                <br/>
                                <span className="o-low"><a href="mailto:lucie@jan.sk">lucie@jan.sk</a></span>
                            </p>
                        </Grid>
                        <Hidden smDown>
                            <ClickAwayListener onClickAway={handleCloseForm}>
                                <Box>
                                    <Button className='btn btn-2 contact-form-btn' onClick={openForm}><Text tid="contact.form"/></Button>
                                    <Grid className={`contact-form-wrapper ${isOpenedForm ? "opened" : ""}`} item xs={6}>
                                        <ContactForm/>
                                    </Grid>
                                </Box>
                            </ClickAwayListener>
                        </Hidden>
                        <Hidden mdUp>
                            <Box className='t-center fullWidth padd-top-dbl push-top-dbl'>
                                <Button className='btn btn-2' onClick={openFormMobile}><Text tid="contact.form"/></Button>
                            </Box>
                            <ContactFormMobile isOpened={isOpenedFormMobile} handleClose={handleCloseFormMobile}/>
                        </Hidden>
                    </Grid>
                </Box>
            </Box>
        </Layout>
    );
}
export default memo(ContactPage);